import React from 'react'
import { Widget } from '@typeform/embed-react'

const Footer = () => {

  return (
    <footer id="contact">
      <div>
        <div className="s-60"></div>
        <div className="s-60"></div>
        <div className="container tc">
          <h2 className="title-intro c-white">BOOK AN APPOINTMENT</h2>
          <div className="s-50"></div>
          <div className="form">
            <Widget id="lPzrXfWa" style={{ width: '100%', height: '100vh' }} />
          </div>
        </div>
        <div className="s-100"></div>
      </div>
      <div className='bg-lite py-3'>
        <div className="container tc">
          <div className="s-20"></div>
          <a href='#top' className='c-medium fw-400'>© 2024 - SmartWorkers. All rights reserved.</a>
          <div className="s-20"></div>
        </div>
      </div>
    </footer>
  );
};

export { Footer }
