import React from 'react'
import wmap from '../../assets/svg/map.svg'
import clients from '../../data/clients'

const Clients = () => {
  return (
    <section id="clients">
      <div className="s-100"></div>
      <div className="map"><img src={wmap} alt="Our Clients" /></div>
      <div className="container">
        <h2 className="title-intro c-white upp">Our Clients</h2>
        <p className="c-white title fw-500">SmartWorkers has over 90 regular clients</p>
      </div>
      <div className="s-50"></div>
      <div className="container tc brd-white bg-white p-5">
        <div className="s-100"></div>
        <div className="s-100"></div>
        <div className="row">
          {clients.map((item)=>
            <div className="col-lg-3" id={item.id}>
              <div className="flex jcc aic border p-5 mx-2 my-3 h-30">
                <div>
                  <img src={item.pic} alt={item.name} width={100} height={100} className='obfc greyscale' />
                  <div className="s-10"></div>
                  <p className="c-medium">{item.name}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export {Clients}
