import React from 'react'

const Capacity = () => {
  return (
    <section id='capacity'>
      <div className="container brd-white top-none bottom-none p-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="s-30"></div>
            <h3 className="title my-4 c-white">Ample resources to deliver results</h3>
            <p className='c-white'>We allocate ample resources to ensure your vacancies are filled with a robust pipeline of potential candidates.</p>
            <div className="s-20"></div>
            <h3 className="title my-4 c-white">Rapid recruitment pace</h3>
            <p className='c-white'>Our advanced capabilities ensure a consistently rapid recruitment pace, from the initial vacancy to the final hire.</p>
            <div className="s-20"></div>
            <h3 className="title my-4 c-white">Ideal for medium and large businesses</h3>
            <p className='c-white'>Our extensive capacity is perfect for medium to large businesses with high hiring volumes or intensive hiring plans.</p>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5">
            <h2 className="title-intro upp">
              <span className="stroke">90</span><br />
              Recruiters<br />on Board
            </h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export {Capacity}
