import React from 'react'
import { Nav } from '../components/Nav'
import { Intro } from './parts/Intro'
import { About } from './parts/About'
import { Capacity } from './parts/Capacity'
import { Technology } from './parts/Technology'
import { Recruiting } from './parts/Recruiting'
import { Cooperation } from './parts/Cooperation'
import { Approach } from './parts/Approach'
import { Clients } from './parts/Clients'
import { Footer } from '../components/Footer'
import { PopUp } from './parts/PopUp'


const Home = () => {
  return (
    <>
      <PopUp />
      <Nav />
      <Intro />
      <About />
      <Capacity />
      <Technology />
      <Recruiting />
      <Cooperation />
      <Approach />
      <Clients />
      <Footer />
    </>
  )
}

export { Home }
