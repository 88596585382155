import React from 'react'

const Recruiting = () => {
  return (
     <section id='recruiting'>
      <div className="s-60"></div>
      <div className="container brd-white bg-white bottom-none p-5">
        <div className="s-20"></div>
        <h2 className="title-intro c-dark">SAFE RECRUITMENT:<br />THREE KEY FEATURES</h2>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-4">
            <p className="stroke c-medium">1</p>
            <h4 className="title my-4 c-medium w-80">Transparent Commission</h4>
            <div className="s-10"></div>
            <p className='c-medium w-90'>Our commission is 10% of the candidate's annual gross income, payable upon successful completion of the probation period.</p>
          </div>
          <div className="col-lg-4">
            <p className="stroke c-medium">2</p>
            <h4 className="title my-4 c-medium w-80">Free Replacement Guarantee</h4>
            <div className="s-10"></div>
            <p className='c-medium w-90'>We offer a free candidate replacement within the first three months of employment, ensuring your satisfaction.</p>
          </div>
          <div className="col-lg-4">
            <p className="stroke c-medium">3</p>
            <h4 className="title my-4 c-medium w-80">Deductible Prepayment</h4>
            <s-10></s-10>
            <p className='c-medium w-90'>We require a $500 prepayment, which is deducted from the final invoice for the candidate.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export {Recruiting}
