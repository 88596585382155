import React from 'react'

const Technology = () => {
  return (
    <section id='technology'>
      <div className="container brd-white top-none p-5">
        <h3 className="title-intro my-4 c-white w-80 mr">WE HANDLE A DIVERSE ARRAY OF TECH STACKS</h3>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-6">
            <h4 className='title-mini upp mb-4'>MAINSTREAM FRONTEND AND BACKEND TECHNOLOGY</h4>
            <p className="c-white meta">
              <span>JavaScript / Front-End</span>
              <span>Fullstack</span>
              <span>Java</span>
              <span>C# / .NET</span>
              <span>Python</span>
              <span>PHP</span>
              <span>Node.js</span>
              <span>iOS</span>
              <span>Android</span>
              <span>React Native</span>
              <span>C / C++ / Embedded</span>
              <span>Flutter</span>
              <span>Golang</span>
              <span>Ruby</span>
              <span>Scala</span>
              <span>Salesforce</span>
              <span>Rust</span>
              <span>Elixir</span>
              <span>Kotlin</span>
              <span>ERP Systems</span>
            </p>
            <div className="s-20"></div>
            <div className="s-1 bg-white"></div>
            <div className="s-40"></div>
            <h4 className='title-mini upp mb-4'>Administrative and managerial positions</h4>
            <p className="c-white meta">
              <span>Marketing</span>
              <span>Sales</span>
              <span>Lead Generation</span>
              <span>SEO</span>
              <span>HR</span>
              <span>Recruiter</span>
              <span>Customer/Technical Support</span>
              <span>Head / Chief</span>
              <span>Finances</span>
            </p>
          </div>
          <div className="col-lg-6">
            <h4 className='title-mini upp mb-4'>specialized Technologies</h4>
            <p className="c-white meta">
              <span>QA Manual</span>
              <span>QA Automation</span>
              <span>Design</span>
              <span>2D/3D Artist / Illustrator </span>
              <span>Gamedev</span>
              <span>Project Manager</span>
              <span>Product Manager</span>
              <span>Product Owner</span>
              <span>Delivery Manager</span>
              <span>Scrum Master / Agile Coach</span>
              <span>Architect / CTO</span>
              <span>DevOps</span>
              <span>Security</span>
              <span>Sysadmin</span>
              <span>Business Analyst</span>
              <span>Data Science</span>
              <span>Data Analyst</span>
              <span>Data Engineer</span>
              <span>SQL / DBA</span>
              <span>Technical Writing</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export {Technology}
