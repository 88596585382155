import React from 'react'

const Cooperation = () => {
  return (
    <section id='cooperation'>
      <div className="container brd-white bg-white top-none p-5 bg-pic-1">
        <h2 className='title-intro c-dark'>PARTNERSHIP<br />OPPORTUNITIES</h2>
        <div className="s-20"></div>
        <div className="row">
          <div className="col-lg-4">
          </div>
          <div className="col-lg-8">
            <h4 className="title my-4 c-medium">Success Fee Recruitment</h4>
            <div className="s-10"></div>
            <p className='c-medium'>No prepayments required, only a success fee. Our 50/50 approach means you pay half on the candidate’s first day and the remaining half after a successful probation period.</p>
            <h4 className="title my-4 c-medium">Recruitment Process Outsourcing (RPO)</h4>
            <div className="s-10"></div>
            <p className='c-medium'>Our dedicated team of recruiters handles your vacancies long-term, under your control and workflow. This scalable solution enhances your recruiting capacity during peak loads and is more cost-effective than Success Fee Recruitment.</p>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </section>
  )
}

export {Cooperation}
