import React from 'react'
import clients from '../../data/clients'

//import React, {useEffect} from 'react'
//import TagManager from 'react-gtm-module'

const Intro = () => {

//  useEffect(() => {
//    TagManager.initialize({ gtmId: 'GTM-PPS98NWB' })
//  }, [])
//
//  const handleButtonClick = () => {
//    TagManager.dataLayer({
//      dataLayer: {
//        event: 'quick_donation_button_click',
//        button_name: 'Intro Quick Donation',
//        category: 'User Actions',
//        action: 'Click',
//        label: 'Submit Quick Donation Button'
//      }
//    })
//  }

  return (
    <section id="top" className='h-100 flex jcc aic'>
      <div className="container bg-white brd-white p-5">
        <div className="row">
          <div className="col-lg-7 flex aic">
            <div className='my-3'>
              <h1 className='title-intro c-medium upp'>IT recruitment<br />company<br />that performs</h1>
              <h1 className='stroke c-medium'>SmartWorkers</h1>
              <div className="s-10"></div>
            </div>
          </div>
          <nav className="col-lg-5 flex aic jcfe tr mobile-hidden">
            <div className='menu'>
              <a href='#about' className='mx-3 px-1 c-dark fw-500'>
                SmartWorkers Hiring
              </a><br /><br />
              <a href='#capacity' className='mx-3 px-1 c-dark fw-500'>
                Extensive Resources
              </a><br /><br />
              <a href='#technology' className='mx-3 px-1 c-dark fw-500'>
                Technology stacks
              </a><br /><br />
              <a href='#recruiting' className='mx-3 px-1 c-dark fw-500'>
                Safe Recruitment
              </a><br /><br />
              <a href='#cooperation' className='mx-3 px-1 c-dark fw-500'>
                Partnership Oportunities
              </a><br /><br />
              <a href='#approach' className='mx-3 px-1 c-dark fw-500'>
                Easy AI Recruiting
              </a><br /><br />
              <a href='#clients' className='mx-3 px-1 c-dark fw-500'>
                Our Clients
              </a>
            </div>
          </nav>
        </div>
        <div className="flex aic jcsa w-60 mobile-block">
          {clients.slice(0, 4).map((item) =>
            <img src={item.pic} alt={item.name} width={80} height={80} className="obfc greyscale" />
          )}
          <span className="px-2 title-sub c-dark val fw-500">+90</span>
          <a href="#clients" className='c-theme fw-500'>See all clients</a>
        </div>
      </div>
    </section>
  )
}

export { Intro }
