import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import {App} from './App'
import ReactGA from 'react-ga4'

ReactGA.initialize("G-RQC7HK6LC5")

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
