import React from 'react'

const About = () => {
  return (
    <section id='about'>
      <div className="container brd-white bottom-none p-5">
        <h2 className="title-intro c-white">BE CLEVER - HIRE SMART</h2>
        <div className="row">
          <div className="s-50"></div>
          <div className="col-lg-7">
            <p className="c-white mb-5">Welcome to Smart Workers, the ultimate destination for innovative and precise recruitment solutions. Our AI-driven approach ensures we don't just find candidates – we find the perfect match for your business, creating a synergy that brings unparalleled happiness and productivity.</p>
            <p className="c-white">In just one year, we've earned the trust of 90 recurring clients, and this number is skyrocketing daily. Our secret? A blend of cutting-edge AI technology and a deep understanding of the IT and Digital sectors, allowing us to connect you with top talent swiftly and efficiently.</p>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-4 brd-white px-5 flex jcc aic">
            <div className="form">
              <h3 className="title c-white">TALK TO US</h3>
              <div className="s-40"></div>
              <a href="https://form.typeform.com/to/lPzrXfWa" target='blank' className='button'>BOOK AN APPOINTMENT</a>
            </div>
          </div>
        </div>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-4">
            <h4 className="title my-4 c-white">Performance Beyond Expectations</h4>
            <p className='c-white'>Our advanced AI tools provide a vast pool of candidates, ensuring a fast and accurate recruitment process that matches the right people with the right roles.</p>
          </div>
          <div className="col-lg-4">
            <h4 className="title my-4 c-white">Seamless and Flexible Workflow</h4>
            <p className='c-white'>Our dedicated account managers offer personalized attention, adapting seamlessly to your company's workflow to ensure a smooth and efficient hiring experience.</p>
          </div>
          <div className="col-lg-4">
            <h4 className="title my-4 c-white">Secure Recruitment Process</h4>
            <p className='c-white'>We pride ourselves on our risk-free recruitment model – you only pay once your ideal candidate is successfully employed, guaranteeing satisfaction and trust.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export { About }
