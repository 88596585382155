import React from 'react'

const Approach = () => {
  return (
    <section id='approach'>
      <div className="s-50"></div>
      <div className="container brd-white px-5">
        <div className="s-30"></div>
        <h3 className="title-intro my-4 c-white w-80 mr">COMFORTABLE<br />AND TRANSPARENT<br />RECRUITING WITH AI</h3>
        <div className="s-40"></div>
        <div className="row">
          <div className="col-lg-7">
            <h4 className="title my-4 c-white">Dedicated Account Manager</h4>
            <p className='c-white w-90'>Your dedicated account manager, enhanced by our AI technology, serves as the sole point of contact. This approach simplifies and speeds up communication.</p>
            <div className="s-20"></div>
            <div className="s-1 bg-white w-90"></div>
            <h4 className="title my-4 c-white">Flexible Workflow</h4>
            <p className='c-white w-90'>Our AI-driven system adapts to the client's workflow, using specified communication channels and systems, and can seamlessly integrate into the client's internal recruitment process if needed.</p>
            <div className="s-20"></div>
            <div className="s-1 bg-white w-90"></div>
            <h4 className="title my-4 c-white">Transparent Terms</h4>
            <p className='c-white w-90'>No exclusivity condition or prepayment required. We share the risk if a candidate does not pass the probationary period, ensuring a transparent and fair process.</p>
            <div className="s-40"></div>
          </div>
          <div className="col-lg-5">
            <div className="s-70"></div>
            <div className="brd-white bg-white p-5 bg-pic-2">
              <p className="w-60 c-dark fw-500">Our AI-driven long-term recruitment service ensures sustainable growth, seamless integration, and immediate impact for your business.</p>
              <div className="s-100"></div>
              <div className="s-100"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export {Approach}
