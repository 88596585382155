import React, { useState, useEffect } from 'react'
import logo from '../logo.svg'

const Nav = () => {
  const [opened, setOpened] = useState(true)
  const [isHeaderVisible, setIsHeaderVisible] = useState(false)

  useEffect(() => {
    if (!opened) {
      document.body.classList.add('menu-active')
    } else {
      document.body.classList.remove('menu-active')
    }
  }, [opened])

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768) {
        setIsHeaderVisible(true)
      } else {
        if (window.scrollY > 100) {
          setIsHeaderVisible(true)
        } else {
          setIsHeaderVisible(false)
        }
      }
    }

    // Установить начальное значение видимости заголовка в зависимости от ширины окна
    if (window.innerWidth <= 768) {
      setIsHeaderVisible(true)
    }

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll) // Обработка изменения размера окна

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  const toggleMenu = () => {
    setOpened(!opened)
  }

  return (
    <header className={`header ${isHeaderVisible ? 'visible' : 'hidden'}`}>
      <div className='container'>
        <nav>
          <h1 id='logo' className='flex aic'>
            <a href='#top' aria-label='Logotype'>
              <img src={logo} width={160} height={64} alt='SmartWorkers' />
            </a>
          </h1>
          <div className="flex jcfe aic">
            <a href="https://form.typeform.com/to/lPzrXfWa" target='blank' className='button'>BOOK AN APPOINTMENT</a>
            <div className="nav-menu">
              <div className={opened ? 'burger' : 'burger open'} onClick={toggleMenu}>
                <span className='tt'></span>
                <span className='mm'></span>
                <span className='bb'></span>
              </div>
              <div className={opened ? 'menu active' : 'menu'}>
                <a href='#about' className='mx-3 px-1'>
                  SmartWorkers Hiring
                </a>
                <a href='#capacity' className='mx-3 px-1' onClick={toggleMenu}>
                  Extensive Resources
                </a>
                <a href='#technology' className='mx-3 px-1' onClick={toggleMenu}>
                  Technology stacks
                </a>
                <a href='#recruiting' className='mx-3 px-1' onClick={toggleMenu}>
                  Safe Recruitment
                </a>
                <a href='#cooperation' className='mx-3 px-1' onClick={toggleMenu}>
                  Partnership Oportunities
                </a>
                <a href='#approach' className='mx-3 px-1' onClick={toggleMenu}>
                  Easy AI Recruiting
                </a>
                <a href='#clients' className='mx-3 px-1' onClick={toggleMenu}>
                  Our Clients
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export { Nav }
